module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://media.lighterair.com/graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":90000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"maxFileSizeBytes":100000000,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheData":false,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lighter-x-air","short_name":"lta","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/lta_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b766aa6a65f424f32193d753a20bf74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51623322-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
